.slide
{
    overflow: hidden;
    position: relative;
    display: block;
    float: left;
    width: 100vw;
    // height is overriden in javascript
    height: 100vh;

    &.slide-spacer
    {
        background-color: transparent;
    }

    .slide-inner
    {
        position: relative;
        height: 100%;
        transform: translate3d(50%, 0, 0);
    }

    .main-clip
    {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        will-change: clip;
        clip: rect(50vh 50vw 50vh 50vw);
    }

    .left-clip
    {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        //clip: rect($padding-vertical calc(100vw - #{$padding-horizontal}) calc(100vh - #{$padding-vertical}) $padding-horizontal);
        // funzt im ios safari nicht deshalb auch in slider-item.js
        clip: rect($padding-vertical 50vw calc(100vh - #{$padding-vertical}) $padding-horizontal);

        .left-flip
        {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;

            -moz-transform: scaleX(-1);
            -o-transform: scaleX(-1);
            -webkit-transform: scaleX(-1);
            transform: scaleX(-1);
            filter: FlipH;
            -ms-filter: "FlipH";

            img.project-image-left {}
        }
    }

    .right-clip
    {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        // funzt im ios safari nicht deshalb auch in slider-item.js
        clip: rect($padding-vertical calc(100vw - #{$padding-horizontal}) calc(100vh - #{$padding-vertical}) 50vw);
    }

    .background-image
    {
        position: relative;
        width: 110%;
        height: 110%;
        top: -5%;
        left: -5%;

        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
    }

    .copy-layer
    {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        > .row
        {
            position: relative;
            height: 100%;

            //> *
            //{
                //opacity: 0;
            //}

            .title
            {
                position: absolute;
                top: calc(50vh - 40px - 2 * #{$copy-line-height} * 20px);
                left: 0;
                text-transform: none;

                @include max-screen($screen-sm-max)
                {
                      font-size: 40px;
                      line-height: 40px;
                      letter-spacing: -1.8px;
                }
            }

            .meta-info
            {
                font-size: 10px;
                text-transform: uppercase;
                letter-spacing: 2px;
                line-height: 2;
            }

            .index
            {
                position: absolute;
                top: calc(25vh + 2 * #{$copy-line-height} * 20px);
                left: 0;
            }

            .category
            {
                position: absolute;
                top: calc(50vh - 40px - 2 * #{$copy-line-height} * 20px + 80px);
                left: 0;
            }

            .description
            {
                position: absolute;
                // overriden in javascript
                //top: calc(75vh - 2 * #{$copy-line-height} * 20px);
                left: 0;
            }
        }
    }

    //&.slide-logo
    //{
    //    transform: translate3d(0, 0, 0);
    //    > *
    //    {
    //        height: 100%;
    //    }
    //    img
    //    {
    //        max-width: 100%;
    //        margin: 0 auto;
    //    }
    //}

    //&.full-width-images
    //{
    //    .project-image , .project-image-left, .project-image-right
    //    {
    //        @extend .full-width;
    //    }
    //}
    //
    //&.full-height-images
    //{
    //    .project-image , .project-image-left, .project-image-right
    //    {
    //        @extend .full-height;
    //    }
    //}
}

.scale
{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform-origin: 50% 50%;
    transform: scale(0.92);
}

//.full-width-align-top
//{
//    position: absolute;
//    width: 110%;
//    height: auto;
//    top: 0;
//    left: -5%;
//}
//
//.full-width
//{
//    position: absolute;
//    width: 110%;
//    height: auto;
//    top: 50%;
//    left: -5%;
//    transform: translateY(-50%);
//}
//
//.full-height
//{
//    position: absolute;
//    height: 110%;
//    width: auto;
//    top: -5%;
//    left: 50%;
//    transform: translateX(-50%);
//}

.vertical-text
{
    float: left;
    transform: rotate(90deg) translate3d(0, 0, 0);
    //-ms-transform-origin: left top 0;
    //transform-origin: left top 0;
    //transform-origin: left center 0;
    transform-origin: left bottom 0;
}