.link-button
{
    position:relative;

    padding-top:14px;
    padding-bottom:14px;
    //opacity:.5;
    //top:0px;

    //margin: 0 auto;


    text-align:center;
}