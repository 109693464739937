.header
{
    position: relative;

    //@include full-margin-width();


    .image-layer
    {
        position: relative;
    }

    h5
    {
        position: absolute;
        width: 100%;
        z-index: 2;
        top: 0;
        text-align: center;

    }

    .arrow_down
    {
        position: absolute;
        bottom: 0;
        width: 100%;

        text-align: center;
        pointer-events: none;
    }

    .copy-layer
    {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        
        width: 80%;

        margin: 0 auto;

        .table
        {
            position: relative;
            height: 100%;
            width: 100%;
        }
    }

    iframe
    {
        position: absolute;
        top: 0;
        left: 0;
        width: percentage(1);
        height: percentage(1);

        opacity: 0;
    }


    @include max-screen($screen-sm)
    {
        .lazy-media
        {
            min-height:400px;
        }

        .copy-layer
        {
            position:relative;
        }
    }
}
