$screen-xs:                  520px !default;

$screen-sm:                  860px !default;
$screen-sm-min:              $screen-sm !default;
$screen-tablet:              $screen-sm-min !default;

$screen-md:                  1024px !default;
$screen-md-min:              $screen-md !default;
$screen-desktop:             $screen-md-min !default;

$screen-lg:                  1280px !default;
$screen-lg-min:              $screen-lg !default;
$screen-lg-desktop:          $screen-lg-min !default;

$screen-xs-max:              ($screen-sm-min - 1) !default;
$screen-sm-max:              ($screen-md-min - 1) !default;
$screen-md-max:              ($screen-lg-min - 1) !default;

$screen-nav-max:             1100px !default;


// flexboxgrid
// Set the gutter between columns.
$gutter-width: 20px;

// Set a margin for the container sides.
$outer-margin: 40px;

$breakpoints:
        s $screen-xs $screen-xs,
        sm $screen-sm $screen-sm,
        md $screen-md $screen-md,
        lg $screen-lg $screen-lg,
        xlg 1920px 1920px;

$flexboxgrid-max-width: 2560px;





// Slide and Detail Header padding
// Must be changed in javascript too
$padding-vertical: calc(100vh * 0.25);
//$padding-horizontal: calc(100vw * 0.15);
$padding-horizontal: calc(((100vw - 2 * #{$outer-margin} - 220px) / 12) + 20px + 40px);