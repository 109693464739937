ul.award-image-list
{
    position:relative;
    left:-10px;

    li
    {
        display:inline-block;
        margin-right:10px;
        margin-left:10px;
    }
}