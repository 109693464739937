article.module-media
{
    img
    {
        width: 100%;
        height: auto;
        margin: 0 auto;
    }

    &.center
    {
        width:percentage(8/12);
        left:percentage(2/12);
    }

    &.small_left
    {
        width:percentage(6/12);
        left:percentage(1/12);
    }

    &.small_right
    {
        width:percentage(6/12);
        left:percentage(5/12);
    }

    &.left
    {
        width:percentage(8/12);
    }

    &.right
    {
        left:percentage(4/12);
        width:percentage(8/12);
    }
}