.link-background
{
    position:relative;

    width:100%;
    height:0;
    padding-bottom:percentage(465 / 1200);
    overflow:hidden;

    h5
    {
        position: absolute;
        width: 100%;
        z-index:2;
        top:0;
        text-align:center;

    }

    .copy-layer
    {
        position:absolute;
        top:0;
        left:0;
        right:0;
        bottom:0;

        width:80%;
        margin: 0 auto;

        .table
        {
            position:relative;
            height:100%;
            width:100%;
        }
    }

    hr
    {
        width:170px;
        background:white;
    }
}