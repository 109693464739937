body
{
	font: 15px/1.7 $font-bold;
	color: $color-copy;
	-webkit-text-size-adjust: 100%;

	@include box-sizing(border-box);

	overflow-x:hidden;
}

hr
{
	background-color: $color-copy;
	height: 1px;
	border: 0;
}

.table
{
	display:table;
	table-layout:fixed;
	width:100%;
}

.table-row
{
	display:table-row;
}

.table-cell
{
	display:table-cell;
	vertical-align:middle;

	&.align-top
	{
		vertical-align:top;
	}

	&.align-middle
	{
		vertical-align:middle;
	}

	&.align-bottom
	{
		vertical-align:bottom;
	}
}

.wrapper
{
	position:relative;
}

input
{
	//@include font-smoothing(); // input Felder müssen font-smoothing noch mal explizit bekommen
	color: $color-copy;
}

.clear-fix
{
	float:none;
	clear:both;
}

*:focus
{
	outline: 0;
}