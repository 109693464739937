@mixin size($size) {
  @if length($size) == 1 {
    @if $size == auto {
      width:  $size;
      height: $size;
    }

    @else if unitless($size) {
      width:  $size + px;
      height: $size + px;
    }

    @else if not(unitless($size)) {
      width:  $size;
      height: $size;
    }
  }

  // Width x Height
  @if length($size) == 2 {
    $width:  nth($size, 1);
    $height: nth($size, 2);

    @if $width == auto {
      width: $width;
    }
    @else if not(unitless($width)) {
      width: $width;
    }
    @else if unitless($width) {
      width: $width + px;
    }

    @if $height == auto {
      height: $height;
    }
    @else if not(unitless($height)) {
      height: $height;
    }
    @else if unitless($height) {
      height: $height + px;
    }
  }
}
