.border-bottom
{
    width:percentage(1/1);
    border-bottom: solid 1px #979797;

    &:last-of-type
    {
    //    border-bottom:none;
    }
}

.line
{
    margin-top:20px;
    margin-bottom:20px;
}

/*
.border-top
{
    border-top: solid 10px $color-copy;
}

.border-bottom-footer
{
    border-bottom: solid 1px $color-highlight;
}

.footer-border-right
{
    background: $color-highlight;
    width:1px;
}

.border-bottom-teaser
{
    border-bottom: solid 1px $color-copy;
}

.border-bottom-teaser-invert
{
    border-bottom: solid 1px $color-copy-invert;
}
*/