ul.link-item-list
{
    position:relative;

    /*li
    {
        display:inline-block;
        margin-right:10px;
        margin-left:10px;
    }*/
}