.item-list
{
    position:relative;

    .list
    {

    }

    &.about
    {
        .item
        {
            margin: 0 auto;
            @include margin-double-bottom;
        }
    }
}