@mixin reset-margin-width()
{
    width:percentage(1/1);
    max-width:percentage(1/1);
    margin: 0 auto;
}


@mixin half-margin-width()
{
    width:percentage(1/1);
    max-width:640px;
    margin: 0 auto;
}

.half-margin-width
{
    @include half-margin-width();
}

@mixin media-margin-width()
{
    width:percentage(1/1);
    max-width:800px;
    margin: 0 auto;
}

.media-margin-width
{
    @include media-margin-width();
}

@mixin full-margin-width()
{
    width:88%;
    max-width:1280px;
    margin: 0 auto;
}

.full-margin-width
{
    @include full-margin-width();
}

@mixin full-margin-width-outer()
{
    width:100%;
    max-width:1440px;
    margin: 0 auto;
}

.full-margin-width-outer
{
    @include full-margin-width-outer();
}