// -webkit-font-smoothing
// Values: none, antialiased (default), subpixel-antialiased

// References
//
// 1. http://maxvoltar.com/sandbox/fontsmoothing/
// 2. http://daneden.me/2010/12/css-font-smoothing/
// 3. https://blog.tigion.de/2013/10/10/moz-osx-font-smoothing-grayscale/

@mixin font-smoothing($value: antialiased) {
  -webkit-font-smoothing: $value;

  @if($value == "antialiased") {
    -moz-osx-font-smoothing: grayscale;
  }
}