@mixin margin-bottom()
{
    margin-bottom: 6rem !important;

    @include max-screen($screen-sm-max)
    {
        margin-bottom: 4rem !important;
    }
}

.margin-bottom
{
    @include margin-bottom();
}


@mixin margin-one-and-half-bottom()
{
    margin-bottom: 8rem !important;

    @include max-screen($screen-sm-max)
    {
        margin-bottom: 6rem !important;
    }
}

.margin-one-and-half-bottom
{
    @include margin-one-and-half-bottom();
}


@mixin margin-double-bottom()
{
    margin-bottom: 12rem !important;

    @include max-screen($screen-sm-max)
    {
        margin-bottom: 8rem !important;
    }
}

.margin-double-bottom
{
    @include margin-double-bottom();
}

@mixin margin-three-quarters-bottom()
{
    margin-bottom: 4.5rem !important;

    @include max-screen($screen-sm-max)
    {
        margin-bottom: 3rem !important;
    }
}

.margin-three-quarters-bottom
{
    @include margin-three-quarters-bottom();
}


@mixin margin-half-bottom()
{
    margin-bottom: 3rem !important;

    @include max-screen($screen-sm-max)
    {
        margin-bottom: 2rem !important;
    }
}

.margin-half-bottom
{
    @include margin-half-bottom();
}


@mixin margin-third-bottom()
{
    margin-bottom: 2.25rem !important;

    @include max-screen($screen-sm-max)
    {
        margin-bottom: 2rem !important;
    }
}

.margin-third-bottom
{
    @include margin-third-bottom;
}

@mixin margin-quarter-bottom()
{
    margin-bottom: 1.5rem !important;

    @include max-screen($screen-sm-max)
    {
        margin-bottom: 1rem !important;
    }
}

.margin-quarter-bottom
{
    @include margin-quarter-bottom();
}

@mixin margin-sixth-bottom()
{
    margin-bottom: 1.125rem !important;

    @include max-screen($screen-sm-max)
    {
        margin-bottom: 0.5rem !important;
    }
}

.margin-sixth-bottom
{
    @include margin-sixth-bottom();
}


@mixin margin-eighth-bottom()
{
    margin-bottom: 0.75rem !important;

    @include max-screen($screen-sm-max)
    {
        margin-bottom: 0.5rem !important;
    }
}

.margin-eighth-bottom
{
    @include margin-eighth-bottom();
}

@mixin margin-sixteenths-bottom()
{
    margin-bottom: 0.375rem !important;

    @include max-screen($screen-sm-max)
    {
        margin-bottom: 0.3rem !important;
    }
}

.margin-sixteenths-bottom
{
    @include margin-sixteenths-bottom();
}

@mixin margin-top()
{
    margin-top: 6rem !important;

    @include max-screen($screen-sm-max)
    {
        margin-top: 4rem !important;
    }
}

.margin-top
{
    @include margin-top();
}

@mixin margin-half-top()
{
    margin-top: 3rem !important;

    @include max-screen($screen-sm-max)
    {
        margin-top: 2rem !important;
    }
}

.margin-half-top
{
    @include margin-half-top();
}

@mixin margin-third-top()
{
    margin-top: 2.25rem !important;

    @include max-screen($screen-sm-max)
    {
        margin-top: 1.5rem !important;
    }
}

.margin-third-top
{
    @include margin-third-top();
}


@mixin margin-quarter-top()
{
    margin-top: 1.5rem !important;

    @include max-screen($screen-sm-max)
    {
        margin-top: 1rem !important;
    }
}

.margin-quarter-top
{
    @include margin-quarter-top();
}

@mixin margin-sixth-top()
{
    margin-top: 1.125rem !important;

    @include max-screen($screen-sm-max)
    {
        margin-top: 0.5rem !important;
    }
}

.margin-sixth-top
{
    @include margin-sixth-top();
}

@mixin margin-eighth-top()
{
    margin-top: 0.75rem !important;

    @include max-screen($screen-sm-max)
    {
        margin-top: 0.5rem !important;
    }
}

.margin-eighth-top
{
    @include margin-eighth-top();
}

@mixin margin-sixteenths-top()
{
    margin-top: 0.375rem !important;

    @include max-screen($screen-sm-max)
    {
        margin-top: 0.3rem !important;
    }
}

.margin-sixteenths-top
{
    @include margin-sixteenths-top();
}

.margin-h4-exception
{
    margin-bottom:43px;

    @include max-screen($screen-sm-max)
    {
        margin-bottom: 28px !important;
    }
}