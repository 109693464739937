.button
{
    color: black;
    z-index: 1;

    display: inline-block;
    padding: .5em .75em;
    border: 0px solid black;
    @include transition(box-shadow .3s,color .5s,background .3s,border .3s);

    font-size: 3.2rem;
    line-height: 1;

    &:hover
    {
        outline-width: 0;
        box-shadow: inset 0 0 0 1.25em black;
    }
}