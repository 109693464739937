.header-home
{
    position: absolute;
    width: 100vw;
    //height: 100vh;
    height: 100%;

    > *
    {
        height: 100%;

        > *
        {
            height: 100%;
        }
    }

    //img.tglc-logo
    //{
    //    max-width: 100%;
    //    margin: 0 auto;
    //}

    .logo-animation
    {
        margin: 0 auto;
        position: relative;
        //opacity: 0;
        width: 100%;
        height: 100%;
        max-width: 700px;

        > *
        {
            //position: absolute;
            //top: 0;
            //left: 0;
            width: 100%;
            height: 100%;
            //height: auto;
        }
    }





    //.image-layer
    //{
    //    width: 100%;
    //    height: 100%;
    //}
    //
    //.header-intro
    //{
    //    img.colored-logo
    //    {
    //        width: 100%;
    //        max-width: 600px;
    //        margin: 0 auto;
    //    }
    //}
    //
    //@include max-screen($screen-sm)
    //{
    //    .header-intro
    //    {
    //        svg
    //        {
    //            width: 100%;
    //        }
    //    }
    //}
}