body.post
{
    .header.header-detail
    {
        z-index: 9;
    }

    .scroll-container
    {
        z-index: 10;
        .scroll-content
        {
            .scroll-content-spacer
            {
                position: relative;
                width: 100vw;
                height: 100vh;
                top: 80vh;
            }
        }
    }

    .project-slider
    {
        pointer-events: none;

        z-index: 8;
        visibility: hidden;

        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        //top: 80vh;
        background-color: lightcyan;

        &.visible
        {
            z-index: 9;
            visibility: visible;
        }
    }
}