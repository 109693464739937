$color-highlight: #f94711;
$color-invert: #ffffff;
$color-copy: #000000;
$color-unimportant: #000000;
$color-background-important: #ffffff;

$color-active: $color-highlight;
$color-nav-active: $color-highlight;

$color-copy-unimportant: $color-unimportant;
$color-copy-invert: $color-invert;
$color-nav-unimportant: $color-unimportant;

$color-background: $color-invert;
$color-background-unimportant: #d8d8d8;

.color-highlight
{
    color : $color-highlight;
}

.color-highlight-bg
{
    background-color : $color-highlight;
}

.color-unimportant-bg
{
    background-color : $color-background-unimportant;
}

@mixin color-copy-invert()
{
    color : $color-copy-invert;
}

.color-copy-invert
{
    @include color-copy-invert();
}

.color-nav-unimportant
{
    color:$color-nav-unimportant;
}


.color-copy-light
{
    p,.copy,.copy-intro,.copy-unimportant
    {
      color:$color-copy-unimportant;
    }
}

.invert
{
    color : $color-invert;
    background:$color-copy;
}

.darken
{
    background:$color-background;
}