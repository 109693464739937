.grid-helper
{
    z-index: 10000;
    pointer-events: none;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    opacity: 0.05;

    //@include main-padding;

    .wrapper
    {
        //max-width: 100%;
    }

    *
    {
        height: 100%;
    }

    .grid-helper-cell
    {
        background-color: red;
    }
}