@mixin link-style()
{
    text-decoration:none;
    //@include user-select(none);

    @include transition(color .3s,background .3s,border .3s);

    & > *
    {
        @include transition(color .3s,background .3s,border .3s);
    }

    &:hover
    {
        //color: $color-highlight !important;

        & > *
        {
            //color: $color-highlight !important;

            .no-hover
            {
                color: $color-copy !important;
            }
        }

        .no-hover
        {
            color: $color-copy !important;
        }
    }


    &.invert
    {
        color:$color-copy-invert !important;

        &:hover
        {


            & > *
            {


                .no-hover
                {
                    color: $color-copy-invert !important;
                }
            }

            .no-hover
            {
                color: $color-copy-invert !important;
            }
        }
    }
}

a
{
    color: $color-copy;
    @include link-style();
}

.invert
{
    color: $color-copy-invert;

    a
    {
        color: $color-copy-invert !important;

        &:hover
        {
            & > *
            {
                .no-hover
                {
                    color: $color-copy-invert !important;
                }
            }

            .no-hover
            {
                color: $color-copy-invert !important;
            }
        }
    }
}