article.module-gallery
{
    text-align:center;

    .overlay
    {
        position:absolute;
        width: 100%;
        height:30%;
        z-index:1;

        opacity:.5;
        pointer-events:none;
    }

    @include min-screen($screen-md)
    {
        @include full-margin-width();
    }

    .royalSlider
    {
        img
        {
            width: 100%;
            height: auto;
            margin: 0 auto;
        }

        .caption
        {
            position:relative;
            text-align:left;
            color:#222;

            h6
            {
                padding-top: 10px;
                padding-left: 0;
                text-transform:none !important;
                line-height:10px;
                color:#222;

                @include max-screen($screen-sm-max)
                {
                    @include full-margin-width();
                }

                @include min-screen($screen-md)
                {
                    padding-left: 10px;
                }
            }
        }

        .rsPlayBtn
        {
            width:90px;
            height:90px;
            margin-left:-45px;
            margin-top:-45px;
        }

        .rsPlayBtnIcon
        {
            width:90px;
            height:90px;
            background:url(/pods/page-stories/modules/module-gallery/play@3x.png) no-repeat;
            background-size:90px 90px;
        }

        .rsCloseVideoBtn
        {
            top:35px;
            right:52px;
            width:52px;
            height:52px;
        }

        .rsCloseVideoIcn
        {
            width:52px;
            height:52px;
            background:url(/pods/page-stories/modules/module-gallery/close@3x.png) no-repeat;
            background-size:52px 52px;
        }
    }

    .menu
    {
        position: relative;
        text-align: right;
        color: $color-copy;
        top: -6px;

        & > *
        {
            display: inline-block;
        }

        .arrow
        {
            position: relative;
            cursor: pointer;
            top: 5px;

            img
            {
                width:31px;
                height:auto;
            }

            &.left
            {
                padding-right:12px;
            }

            &.right
            {
                padding-left:12px;
            }
        }

        .counter
        {
            width:20px;
            text-align:center;
        }

        .spacer
        {
            width:10px;
            text-align:center;
        }

        .max
        {
            width:20px;
            text-align:center;
        }
    }

    .rsBullets
    {
        bottom:15px;
        width:100%;

        .rsBullet
        {
            margin-right: 10px;
            margin-left: 10px;
        }

        .rsNavItem > span
        {
            border-radius: 10px;
            border: 1px solid white;

            background: transparent;
            box-shadow: none;
            width: 7px;
            height: 7px;

            &:hover
            {
               background: #fff;
            }
        }

        .rsNavSelected > span
        {
            background: #fff;
        }
    }

    .icon-back
    {
        left: 0;
    }

    .icon-next
    {
        right: 0;
    }
}