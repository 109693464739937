.link-image
{
    position:relative;

    width:100%;
    height:0;
    padding-bottom:percentage(330 / 1200);
    overflow:hidden;

    h5
    {
        position: absolute;
        width: 100%;
        z-index:2;
        top:0;
        text-align:center;

    }

    .lazy-media
    {
        height:100%;

        &.loaded
        {
            opacity:1;
        }
    }

    @include max-screen($screen-sm)
    {
        .lazy-media
        {

        }
    }

    .image-layer
    {
        position:relative;
    }

    .copy-layer
    {
        position:absolute;
        top:0;
        left:0;
        right:0;
        bottom:0;

        width:80%;
        margin: 0 auto;

        .table
        {
            position:relative;
            height:100%;
            width:100%;
        }
    }

    hr
    {
        width:170px;
        background:white;
    }

    @include max-screen($screen-xs)
    {
        padding-bottom:percentage(164 / 355);

        .lazy-media
        {
            min-height:164px;
        }
    }
}