$copy-line-height: 1.4;

@mixin h1
{
    font-size: 80px;
    //line-height: 125%;
    line-height: 1;
    @include font-smoothing();

    @include max-screen($screen-sm-max)
    {
      //  font-size: 40px;
      //  line-height: 40px;
      //  letter-spacing: -1.8px;
    }
}

h1
{
    @include h1;
}


@mixin h2()
{
    font-size: 72px;
    line-height: 125%;
    @include font-smoothing;
}

h2
{
   @include h2;
}

@mixin h3()
{
    font-size: 48px;
    line-height: 125%;
    @include font-smoothing;
}

h3
{
    @include h3;
}


@mixin h4()
{
    font-size: 24px;
    line-height: 117%;
    @include font-smoothing;
}

h4
{
    @include h4;

    & > *
    {
        @include h4;
    }
}

@mixin h5()
{
    font-size: 18px;
    line-height: 133%;
    @include font-smoothing;
}

h5
{
    @include h5;
}


@mixin h6()
{
    font-size: 10px;
    line-height: 200%;
    letter-spacing: 2px;
    text-transform:uppercase;
}

h6
{
    @include h6;
}

@mixin copy
{
    font-size: 20px;
    line-height: $copy-line-height;

    a
    {
        border-bottom: solid 1px;
    }

    @include max-screen($screen-sm-max)
    {
        font-size: 16px;
        line-height: $copy-line-height;
        letter-spacing: 0.3px;
    }
}

.copy, p
{
    @include copy();
}

nav
{
    @include h4;

    li
    {
       margin-bottom:30px;
    }

    .current_page_item
    {
       // font-family:$font-black;
        @include transform(translate3d(0,0,0));
    }

    @include max-screen($screen-sm-max)
    {
       // font-size: 60px;
       // line-height: 60px;
       // letter-spacing: -1.6px;
    }
}

#logo
{

}

a
{
    &:hover
    {
        //color: $color-highlight !important;
    }
}

.regular
{
    //font-family: $font-bold !important;
}

.bold
{
    //font-family: $font-bold !important;
}

.black
{
   // font-family: $font-bold !important;
}

.uppercase
{
    text-transform: uppercase;
}

.center
{
    text-align: center;
}

u
{
    text-decoration: none;
}

nav
{
    .current-menu-item,.current_page_item
    {
        a
        {
            color: $color-active !important;
        }
    }
}

@mixin hyphen()
{
    -ms-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
}

.hyphen
{
    @include hyphen;
}