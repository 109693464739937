.mixin-header-motion
{
    text-rendering: optimizeSpeed;

    .copy-layer
    {
        opacity:0;
    }

    .lazy-media
    {
        @include transition(none);
        opacity:0;

        .image-wrapper
        {
            opacity:0;
            background:black;
        }

        img
        {
            opacity:.8;
            @include transition(none);
        }

        &.loaded
        {
            opacity:1;
            @include transition(none);

            img
            {
                opacity:.8;
            }
        }
    }
}