/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('../font/fontello.svg?1834034#fontello') format('svg');
  }
}
*/

[class^="icon-"]:before, [class*=" icon-"]:before {
    font-family: "fontello";
    font-style: normal;
    font-weight: normal;
    speak: none;

    display: inline-block;
    text-decoration: inherit;
    width: 1em;
    margin-right: .2em;
    text-align: center;
    /* opacity: .8; */

    /* For safety - reset parent styles, that can break glyph codes*/
    font-variant: normal;
    text-transform: none;

    /* fix buttons height, for twitter bootstrap */
    line-height: 1em;

    /* Animation center compensation - margins should be symmetric */
    /* remove if not needed */
    margin-left: .2em;

    /* you can be more comfortable with increased icons size */
    /* font-size: 120%; */

    /* Font smoothing. That was taken from TWBS */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    /* Uncomment for 3D effect */
    /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}


.icon-bahn:before { content: '\e800'; } /* 'î €' */
.icon-card:before { content: '\e801'; } /* 'î ' */
.icon-back:before { content: '\e802'; } /* 'î ‚' */
.icon-email:before { content: '\e803'; } /* 'î ƒ' */
.icon-menu:before { content: '\e804'; } /* 'î „' */
.icon-mobile:before { content: '\e805'; } /* 'î …' */
.icon-parken:before { content: '\e806'; } /* 'î †' */
.icon-pfeil_down:before { content: '\e807'; } /* 'î ‡' */
.icon-pfeil_links:before { content: '\e808'; } /* 'î ˆ' */
.icon-pfeil_rechts:before { content: '\e809'; } /* 'î ‰' */
.icon-pfeil_up:before { content: '\e80a'; } /* 'î Š' */
.icon-pin:before { content: '\e80b'; } /* 'î ‹' */
.icon-share:before { content: '\e80c'; } /* 'î Œ' */
.icon-uhr:before { content: '\e80d'; } /* 'î ' */
.icon-close:before { content: '\e80e'; } /* 'î Ž' */
.icon-next:before { content: '\e80f'; } /* 'î ' */
.icon-twitter:before { content: '\e810'; } /* 'î ' */
.icon-facebook:before { content: '\e811'; } /* 'î ‘' */
.icon-external:before { content: '\e812'; } /* 'î ’' */