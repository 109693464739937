article.module-copy
{
    text-align: center;
    margin: 0 auto;
    width:60%;

    .copy
    {
        & > *
        {
            &:first-child
            {
                margin-top:0 !important;
            }

            margin-top:40px !important;
        }
    }
}