.lazy-media,.lazy-media-loaded
{
    position:relative;
    overflow:hidden;
    background:$color-background;
    @include transition(background 0s .6s);

    .height-container
    {

    }

    img
    {
        position:relative;
        width:100%;
        //height:100%;
        opacity:0;
        //object-fit: cover;

        @include user-select(none);
        @include transition(opacity .6s $ease-out-quad);
    }

    &.lazy-media-loaded
    {
        img
        {
            opacity:1;
        }
    }

    .image-wrapper
    {
        position:absolute;
        width:100%;
        height:100%;
        overflow:hidden;
    }

    .video-play-btn
    {
        position:absolute;
        width:71px;
        height:71px;
        top:50%;
        left:50%;
        margin-left:-35px;
        margin-top:-35px;

        background: url("/partials/lazy-media/assets/play@3x.png");
        background-size:71px 71px;
        background-repeat:no-repeat;
    }

    .autoplay-video
    {
        position:absolute;
        top:0;
        left:0;
        width:100%;
        height:100%;
    }


    video
    {
        position:absolute;
        top:0;
        left:0;
        width:percentage(1);
        height:percentage(1);
    }

    &.background
    {
        position:absolute;
        width:100%;
        height:100%;
        top:0;

        @include user-select(none);

        .image
        {
            opacity:0;
            @include transition(opacity .6s $ease-out-quad);
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;

            position:relative;
            top:0;
            width:100%;
            height:100%;
        }
    }

    &.loaded
    {
        background:transparent;

        img,.image
        {
            opacity: 1;
        }
    }
}