#modules
{
    //top: 100vh;
    //  @include full-margin-width();

    @include max-screen($screen-sm)
    {
        //    @include full-margin-width();
    }
}

article.module
{
    position: relative;

    padding-top: 30px;
    padding-bottom: 30px;

    &:first-of-type
    {
        padding-top: 80px;
    }

    &:last-of-type
    {
        padding-bottom: 80px;
    }
}

//modules
@import "header/header";
@import "header/header-home";
@import "header/header-detail";
@import "header/header-page";
@import "module-copy/module-copy";
@import "module-gallery/module-gallery";
@import "module-media/module-media";